<template>
  <div class="relative w-full h-full flex flex-row justify-center items-center">
    <svg class="w-full h-full transform -rotate-90" viewBox="0 0 120 120">
      <circle class="text-new-black opacity-25" cx="60" cy="60" r="54" fill="none" stroke="currentColor" stroke-width="12"/>
      <circle class="text-new-black" cx="60" cy="60" r="54" fill="none" stroke="currentColor" stroke-width="12" stroke-dasharray="339.292" :stroke-dashoffset='circleIndicator' stroke-linecap="round"/>
    </svg>
    <div class="absolute inset-0 w-full h-full flex flex-row justify-center items-center text-sm font-visby text-new-black">{{ percent }}%</div>
  </div>
</template>

<script>
export default {
  name: 'circleProgress',
  props: ['softColor', 'strongColor', 'done', 'total'],

  computed: {
    circleIndicator() {
      // eslint-disable-next-line no-mixed-operators
      return 339.292 * (1 - ((100 / this.total * this.done) / 100));
    },
    percent() {
      return Math.round((100 / this.total) * this.done);
    },
  },
};
</script>
