<template>
  <transition @enter="slideInBottom" @leave="slideOutBottom" :css="false" mode="out-in">
    <div v-if="showOverlay === 'overview'" class="w-full h-auto md:h-full fixed top-0 md:flex md:flex-row md:justify-center md:items-center md:inset-0 z-30 font-visby text-new-black pointer-events-none">
      <div class="relative w-full md:w-5/12 md:min-w-100 h-screen min-h-screen md:min-h-0 md:h-auto md:max-h-80  md:overflow-y-auto md:bg-new-yellow md:rounded-t-3xl md:rounded-3xl md:p-5 pointer-events-auto scrollbar flex flex-col">

        <!-- Content -->
        <div class="w-full h-1/6 flex-shrink-0" @click="closeOverview"></div>
        <div class="w-full h-5/6 bg-new-yellow rounded-t-3xl p-5 flex flex-col overflow-y-auto scrollbar">

          <!-- Heading -->
          <div class="text-xl cursor-pointer" @click="closeOverview">schließen</div>
          <div class="mt-6 font-visby font-bold text-3xl">Fragenübersicht</div>

          <!--  Shortcuts -->
          <div class="flex flex-row flex-wrap mt-5 font-visby">
            <div v-for="(question, index) in questions" :key=question>
              <div class="transition duration-150 ease-in-out w-8 h-8 mr-5 mb-5 text-base bg-white rounded-full border border-new-black flex-shrink-0 flex flex-row justify-center items-center cursor-pointer hover:bg-new-black hover:text-white"
                  :class="{
                    'ring ring-new-black' : currentQuestionId === question.id,
                    'bg-red-600 text-white' : getResult(question.id).result === 'wrong',
                    'bg-green-500' : getResult(question.id).result === 'correct',
                    'bg-new-black text-white' : getResult(question.id).result === null && getResult(question.id).selectedAnswersIds.length > 0
                  }"
                @click="$emit('changeQuestion', index)">{{index + 1}}</div>
            </div>
          </div>
          <!--  Shortcuts -->

          <resultLegend class="mb-28"/>

        </div>
        <!-- Content -->

        <!-- Button -->
        <div v-if="!reviewMode" class="fixed bottom-0 md:static pb-5 min-h-28 w-full p-5 bg-new-yellow md:bg-new-yellow md:shadow-none md:border-t-2 md:border-new-yellow2">
          <customButton label='Prüfung abgeben' @click="$emit('buttonClicked')"  />
        </div>
        <!-- Button -->


      </div>
    </div>
  </transition>
</template>

<script>
// Mixins
import slideInOutBottom from '../../../mixins/animations/slideInOutBottom.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';

// Components
import resultLegend from '../../training/components/resultLegend.vue';
import customButton from '../../../symbols/customButton.vue';

export default {
  name: 'overview',
  props: ['currentQuestionId', 'reviewMode'],
  mixins: [slideInOutBottom, iosVibrations],
  components: { resultLegend, customButton },

  computed: {
    showOverlay() {
      return this.$store.state.layout.showOverlay;
    },
    questions() {
      return this.$store.state.exam.questions;
    },
  },

  methods: {
    getResult(questionId) {
      return this.$store.getters.currentExamProgress(questionId);
    },

    closeOverview() {
      this.iosVibrate(0);
      this.$store.commit('showOverlay', null);
    },
  },
};
</script>
