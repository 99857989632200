<template>
  <div class="text-new-black font-visby">
    <div class="mt-5 mb-1 text-xs font-bold">Legende</div>
    <div class="flex flex-row items-center text-xs mt-5">
      <div class="w-8 h-8 text-base bg-white rounded-full border border-new-black flex-shrink-0 flex flex-row justify-center items-center"></div>
      <div class="ml-3">Unbeantwortet</div>
    </div>
    <div class="flex flex-row items-center text-xs mt-3">
      <div class="w-8 h-8 text-base bg-new-black rounded-full border border-new-black flex-shrink-0 flex flex-row justify-center items-center"></div>
      <div class="ml-3">Beantwortet</div>
    </div>
    <div class="flex flex-row items-center text-xs mt-3">
      <div class="w-8 h-8 text-base bg-red-600 rounded-full border border-new-black flex-shrink-0 flex flex-row justify-center items-center"></div>
      <div class="ml-3">Falsch beantwortet</div>
    </div>
    <div class="flex flex-row items-center text-xs mt-3">
      <div class="w-8 h-8 text-base bg-green-500 rounded-full border border-new-black flex-shrink-0 flex flex-row justify-center items-center"></div>
      <div class="ml-3">Richtig beantwortet</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'resultLegend',
};
</script>
