<template>
  <transition @enter="slideInBottom" @leave="slideOutBottom" :css="false" mode="out-in">
    <div v-if="showOverlay === 'results'" class="w-full h-auto md:h-full fixed top-0 md:flex md:flex-row md:justify-center md:items-center md:inset-0 z-30 font-visby text-new-black">
      <div class="relative w-full md:w-5/12 md:min-w-100 h-screen min-h-screen md:min-h-0 md:h-auto md:max-h-80  md:overflow-y-auto md:bg-new-yellow md:rounded-t-3xl md:rounded-3xl md:p-5 pointer-events-auto scrollbar flex flex-col">

        <!-- Content -->
        <div class="w-full flex-grow flex-shrink-0"></div>
        <div class="w-full h-auto bg-new-yellow rounded-t-3xl p-5">

          <!-- Heading -->
          <div class="mt-6 font-visby font-bold text-3xl">Auswertung</div>

          <div class="mt-5">Du hast {{ totalCorrect }} von {{ max }} Fragen richtig beantwortet.</div>
          <div class="mt-3">
            <div v-if="percentageCorrect >= (currentExam?.exam?.attributes?.percentageToFinish || defaultPercentageToFinish)">Du hast {{ percentageCorrect }}% von {{ currentExam?.exam?.attributes?.percentageToFinish || defaultPercentageToFinish }}% erreicht und bestanden. Herzlichen Glückwunsch 🎉.</div>
            <div v-else>Du hast {{ percentageCorrect }}% von {{ currentExam?.exam?.attributes?.percentageToFinish || defaultPercentageToFinish }}% erreicht und damit leider nicht bestanden.</div>
          </div>
          <div class="w-full mt-10 flex flex-row justify-center items-center">
            <div class="w-1/2">
              <circleProgress class="w-full h-full" :done="totalCorrect" :total="max" />
            </div>
          </div>


        </div>

        <div class="w-full p-5 flex-shrink-0 bg-new-yellow">
          <customButton class="mt-3" :loading="!allowedToClose" label='Fragen nochmal anschauen' @click="review"/>
          <customButton class="mt-3" :loading="!allowedToClose" label='Beenden' @click="close"/>
        </div>
        <!-- Content -->


      </div>
    </div>
  </transition>
</template>

<script>
// Mixins
import slideInOutBottom from '../../../mixins/animations/slideInOutBottom.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';

// SVG
import circleProgress from '../../../assets/svg/circleProgress.vue';

// Components
import customButton from '../../../symbols/customButton.vue';

export default {
  name: 'results',
  props: ['max', 'saveType'],
  mixins: [slideInOutBottom, iosVibrations],
  components: { customButton, circleProgress },

  data() {
    return {
      defaultPercentageToFinish: 75,
      allowedToClose: false,
    };
  },

  computed: {
    showOverlay() {
      return this.$store.state.layout.showOverlay;
    },
    totalCorrect() {
      return this.$store.state.exam.totalCorrect;
    },
    currentExam() {
      return this.$store.state.exam.exam;
    },
    percentageCorrect() {
      // eslint-disable-next-line no-mixed-operators
      return Math.round(100 / this.max * this.totalCorrect);
    },
    me() {
      return this.$store.state.profile.me;
    },
    myExamProgress() {
      return this.me.userData.data.attributes.examProgress;
    },
    currentProgress() {
      return this.$store.state.exam.currentProgress;
    },
    userDataId() {
      return this.me?.userData?.data?.id;
    },
    saveResults() {
      return this.$store.state.saveResults;
    },
    queryMeEx() {
      return this.$store.state.queryMeEx;
    },
    examMode() {
      return this.$store.state.exam.mode;
    },
    currentCourseId() {
      return this.$store.state.settings.currentCourseID;
    },
    queryMyExamsEx() {
      return this.$store.state.queryMyExamsEx;
    },
  },

  watch: {
    showOverlay() {
      if (this.showOverlay === 'results' && this.examMode !== 'review') {
        this.allowedToClose = false;
        this.queryMeEx().then((result) => {
          if (result.error) {
            console.log(result.error.message);
          } else {
            const examToSave = [];
            const myExamProgress = result.data.me.userData.data.attributes.examProgress;
            myExamProgress.forEach((examProgress) => {
              examToSave.push({
                id: examProgress.id,
                courseId: Number(this.currentCourseId),
                finished: examProgress.finished,
                passed: examProgress.passed,
                started: examProgress.started,
                progress: examProgress.progress,
                pruefungen: examProgress.pruefungen.data.id,
              });
            });
            const examIndex = examToSave.findIndex((exam) => exam.pruefungen === this.currentExam.id);
            const myExam = examToSave[examIndex];
            myExam.finished = true;
            myExam.progress = this.currentProgress;
            myExam.totalCorrect = this.totalCorrect;
            myExam.passed = this.percentageCorrect >= (this.currentExam?.exam?.attributes?.percentageToFinish || this.defaultPercentageToFinish);
            this.saveResults({ userDataId: this.userDataId, examProgress: examToSave }).then((result2) => {
              if (result.error) {
                console.log(result2.error.message);
              } else {
                this.allowedToClose = true;
              }
            });
          }
        });
      }
    },
  },

  methods: {
    review() {
      if (this.allowedToClose) {
        this.queryMyExamsEx().then(() => {
          this.$store.commit('changeMode', 'review');
          this.$store.commit('showOverlay', null);
        });
      }
    },

    close() {
      if (this.allowedToClose) {
        this.queryMyExamsEx().then(() => {
          this.$store.commit('showOverlay', null);
          this.$router.replace({ name: 'exam' });
        });
      }
    },
  },
};
</script>
